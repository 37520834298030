$break-small: 320px;
$break-medium: 992px;
$break-large: 1200px;


$color1: #332B39;
$color2: #43384B;

body {
  font-size: 1rem;
  color: #ffffff;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
}

h4, .h4 {
  font-size: 1.125rem;
}

p, ul, li, ol, h1, h2, h3, h4, h5, figure {
  margin: 0;
  padding: 0;
  list-style: none;
  font-weight: normal;
}


.bg-dark {
  background-color: rgba(0, 0, 0, 0.98) !important;
}


.fullPageWrapper {
  width: 100%;
  padding-top: 0;

  display: flex;
  flex-direction: row;
  padding-left: 0;
  padding-right: 0;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-0 {
  padding-top: 0px !important;
}

.pb-0 {
  padding-bottom: 0px !important;
}

.pl-0 {
  padding-left: 0px !important;
}
.pr-0 {
  padding-right: 0px !important;
}

.contentWrapper {
  width: 100%;
  -webkit-flex-grow: 1;
  flex-grow: 1;
}



.login {
  padding: 0px;
  text-align: left;
  @media screen and (min-width: $break-medium) {
    padding: 20px;
    padding-left: 2rem;
    padding-right: 2rem;
    height: 85vh;
  }
  height: 100vh;
  background-color: $color2;



  background-image: url("/images/signup/bg.jpg");
  background-size: cover;
  background-position: center;

}

.bgC {
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}



.field-error {
  width: 100%;
  margin-top: .25rem;
  font-size: 80%;
  color: #dc3545;
}


.countrySelection {
  display: flex;


  input{
    margin:0;
    padding:0;
    appearance:none !important;
    display: none;
  }


  .country {
    background: #007bff;
    border-radius: 10px;
  }

}


.cc-selector
.visa{background-image:url(http://i.imgur.com/lXzJ1eB.png);}
.mastercard{background-image:url(http://i.imgur.com/SJbRQF7.png);}

.cc-selector input:active +.drinkcard-cc{opacity: .9;}
.cc-selector input:checked +.drinkcard-cc{
  -webkit-filter: none;
  -moz-filter: none;
  filter: none;
}
.drinkcard-cc{
  cursor:pointer;
  background-size:contain;
  background-repeat:no-repeat;
  display:inline-block;
  width:100px;height:70px;
  -webkit-transition: all 100ms ease-in;
  -moz-transition: all 100ms ease-in;
  transition: all 100ms ease-in;
  -webkit-filter: brightness(1.8) grayscale(1) opacity(.7);
  -moz-filter: brightness(1.8) grayscale(1) opacity(.7);
  filter: brightness(1.8) grayscale(1) opacity(.7);
}
.drinkcard-cc:hover{
  -webkit-filter: brightness(1.2) grayscale(.5) opacity(.9);
  -moz-filter: brightness(1.2) grayscale(.5) opacity(.9);
  filter: brightness(1.2) grayscale(.5) opacity(.9);
}



.form-group .form-control {
  background: transparent;
  border-radius: .3rem !important;
  font-size: .9375rem;
}


.form-control, .asColorPicker-input, .dataTables_wrapper select, .jsgrid .jsgrid-table .jsgrid-filter-row input[type=text], .jsgrid .jsgrid-table .jsgrid-filter-row select, .jsgrid .jsgrid-table .jsgrid-filter-row input[type=number], .select2-container--default .select2-selection--single, .select2-container--default .select2-selection--single .select2-search__field, .typeahead, .tt-query, .tt-hint {
  border: 1px solid #c9ccd7;
  font-weight: 400;
  font-size: 0.875rem;
}


.form-control-lg {
  height: 3.4rem;
  padding: 0.94rem 1.94rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}


.register-form-btn {
  text-transform: uppercase;
  padding: 1rem 0.5rem;
  line-height: 1.5;
  height: 3.4rem !important;
}

.register-form-combine {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  margin-left: -10px;
}

.btn.btn-lg {
  font-size: 0.875rem;
}


.logo {
  height: 35px;
  @media screen and (min-width: $break-medium) {
    height: 33px;
  }
  margin-bottom: 20px;
  margin-top: 20px
}


.loginContainer {
  width: 100%;
  @media screen and (min-width: $break-small) {
    //height: 100vh;
  }

  &.row {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

}


.section {
  position: relative;
  padding: 7em 1em;
  background-color: $color2;
  outline: none;


  &.imprint {
    padding: 0px;

    a {
      color: #6e6e6e;
    }
  }


  &:nth-child(even) {background: $color1}

  &.row {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }


  .separate-bow {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    margin: -1.5em 0 0 -2.5em;
    width: 5em;
    height: 5em;
    background: #c84da1 center;
    background-size: 90%;
    border-radius: 50%;
    justify-content: center;
    display: flex;
    align-items: center;
  }

  .circles {
    margin: -5em 0;
    font-size: 0.8em;

    justify-content: center;

    @media screen and (min-width: $break-medium) {
      display: flex;
      flex-direction: row;
      margin: 0;
      font-size: 1.3vw;
    }


    li {
      margin: 5em 0;
      @media screen and (min-width: $break-medium) {
        flex: 1;
        margin: 0;
      }
    }

    .circle {
      margin: 0 auto;
      width: 75vw;
      max-width: 25em;
      height: 75vw;
      max-height: 25em;
      border-radius: 50%;
      background-color: $color1;
      text-align: center;

      @media screen and (min-width: $break-medium) {
        margin: 0 auto;
        width: 17em;
        height: 17em;
        border-radius: 50%;
        background-color: $color1;
        text-align: center;
      }

      img {
        margin-top: 3em;
        width: 8em;

        @media screen and (min-width: $break-medium) {
          margin-top: 2em;
          width: 6em;
        }

      }

      h3 {
        margin: 0 0 0.2em;
        font-size: 30px;
        line-height: 1;

        @media screen and (min-width: $break-medium) {
          margin: 0 0 0.2em;
          font-size: 30px;
          line-height: 1;
        }
      }

      p {
        margin: 0;
        padding: 0 3em;
        font-weight: bold;
        text-align: center;

        @media screen and (min-width: $break-medium) {
          padding: 0 4em;
          font-size: 0.7em;
        }
      }

    }

  }


  h2 {
    margin-bottom: 0.1em;
    font-weight: bold;
    font-size: 2em;
    text-align: center;
    line-height: 1;
  }

  h3 {
    margin-bottom: 2em;
    font-weight: bold;
    font-size: 0.8em;
    text-align: center;
  }

  p {
    margin-top: 2em;
    text-align: justify;
  }

}


.font-weight-light {
  font-weight: 300 !important;
}


.regHeader {
  text-align: center;
}


.loadButton {
  position: relative;

  .loadSpinner {
    width: 20px;
    height: 20px;
    display: block;
    color: white;
    position: absolute;
    left: 20px;
  }

}




.footer {
  position: relative;
  bottom: 0;
  width: 100%;
  height: 40px;
  line-height: 40px;
  background-color: #f5f5f5;
  font-size: 13px;
}


.navbar {
  display: flex;
  justify-content: flex-start;
  z-index: 100;
}


.content {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  align-content: stretch;


  .form-group {
    width: 100%;
  }
}




.SlideIn-appear {
  transform: translateX(30px);
  -webkit-transform: translateX(30px);
  -moz-transform: translateX(30px);
  -o-transform: translateX(30px);
  -ms-transform: translateX(30px);
  opacity: 0;
}

.SlideIn-appear.SlideIn-appear-active {
  opacity: 1;
  transform: translateX(0);
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -o-transform: translateX(0);
  -ms-transform: translateX(0);
  transition: all 0.6s linear;
}
.SlideIn-enter {
  opacity: 0;
  transform: translateX(30px);
  -webkit-transform: translateX(30px);
  -moz-transform: translateX(30px);
  -o-transform: translateX(30px);
  -ms-transform: translateX(30px);
}
.SlideIn-enter.SlideIn-enter-active {
  opacity: 1;
  transform: translateX(0);
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -o-transform: translateX(0);
  -ms-transform: translateX(0);
  transition: all 0.2s linear 0.4s;
}
.SlideIn-leave {
  opacity: 1.0;
  transform: translateX(0);
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -o-transform: translateX(0);
  -ms-transform: translateX(0);
}
.SlideIn-leave.SlideIn-leave-active {
  opacity: 0;
  position: absolute;
  width: 100%;
  transform: translateX(-30px);
  -webkit-transform: translateX(-30px);
  -moz-transform: translateX(-30px);
  -o-transform: translateX(-30px);
  -ms-transform: translateX(-30px);
  transition: all 0.2s linear;
}







.SlideOut-appear {
  transform: translateX(-30px);
  -webkit-transform: translateX(-30px);
  -moz-transform: translateX(-30px);
  -o-transform: translateX(-30px);
  -ms-transform: translateX(-30px);
  opacity: 0;
}

.SlideOut-appear.SlideOut-appear-active {
  opacity: 1;
  transform: translateX(0);
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -o-transform: translateX(0);
  -ms-transform: translateX(0);
  transition: all 0.6s linear;
}
.SlideOut-enter {
  opacity: 0;
  transform: translateX(-30px);
  -webkit-transform: translateX(-30px);
  -moz-transform: translateX(-30px);
  -o-transform: translateX(-30px);
  -ms-transform: translateX(-30px);
}
.SlideOut-enter.SlideOut-enter-active {
  opacity: 1;
  transform: translateX(0);
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -o-transform: translateX(0);
  -ms-transform: translateX(0);
  transition: all 0.2s linear 0.4s;
}
.SlideOut-leave {
  opacity: 1.0;
  transform: translateX(0);
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -o-transform: translateX(0);
  -ms-transform: translateX(0);
}
.SlideOut-leave.SlideOut-leave-active {
  opacity: 0;
  position: absolute;
  width: 100%;
  transform: translateX(30px);
  -webkit-transform: translateX(30px);
  -moz-transform: translateX(30px);
  -o-transform: translateX(30px);
  -ms-transform: translateX(30px);
  transition: all 0.2s linear;
}


/*--------------------
Mixins
--------------------*/
@mixin center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin ball {
  @include center;
  content: '';
  display: block;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background: rgba(255, 255, 255, .5);
  z-index: 2;
  margin-top: 4px;
  animation: ball .45s cubic-bezier(0, 0, 0.15, 1) alternate infinite;
}


.chat {
  height: 75vh;
  width: 95%;
  max-height: 600px;
  z-index: 2;
  overflow: hidden;
  box-shadow: 0 5px 30px rgba(0, 0, 0, .5);
  background: rgba(0, 0, 0, .7);
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media screen and (min-width: $break-medium) {
    width: 40%;
  }
}

.chat-title {
  flex: 0 1 65px;
  position: relative;
  z-index: 2;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  text-transform: uppercase;
  text-align: left;
  padding: 10px 10px 10px 70px;
  justify-content: center;
  align-items: flex-start;
  display: flex;
  flex-direction: column;

  h1, h2 {
    font-weight: normal;
    font-size: 14px;
    margin: 0;
    padding: 0;
  }

  h2 {
    color: rgba(0, 255, 95, 0.78);
    font-size: 10px;
    letter-spacing: 1px;
  }

  .avatar {
    position: absolute;
    z-index: 1;
    top: 8px;
    left: 9px;
    border-radius: 30px;
    width: 50px;
    height: 50px;
    overflow: hidden;
    margin: 0;
    padding: 0;
    border: 2px solid rgba(255, 255, 255, 0.24);

    img {
      width: 100%;
      height: auto;
    }
  }



}


.sendButtonWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 1rem;


  .form-control {
    background-color: white;
  }

}



/*--------------------
Messages
--------------------*/
.messages {
  flex: 1 1 auto;
  color: black;
  overflow-x: hidden;
  overflow-y: scroll;
  position: relative;
  width: 100%;

  & .messages-content {
    position: absolute;
    top: 0;
    left: 0;
    height: 101%;
    width: 100%;
  }


  .message {
    clear: both;
    float: left;
    padding: 6px 10px 7px;
    border-radius: 10px 10px 10px 0;
    background: white;
    margin: 8px 0;
    font-size: 14px;
    line-height: 1.4;
    margin-left: 45px;
    margin-right: 10px;
    position: relative;
    text-shadow: 0 1px 1px rgba(0, 0, 0, .2);

    .timestamp {
      position: absolute;
      bottom: -15px;
      font-size: 9px;
      color: rgba(255, 255, 255, .3);
    }

    &::before {
      content: '';
      position: absolute;
      bottom: -6px;
      border-top: 6px solid rgba(0, 0, 0, .3);
      left: 0;
      border-right: 7px solid transparent;
    }

    .avatar {
      position: absolute;
      z-index: 1;
      bottom: -15px;
      left: -35px;
      border-radius: 30px;
      width: 30px;
      height: 30px;
      overflow: hidden;
      margin: 0;
      padding: 0;
      border: 2px solid rgba(255, 255, 255, 0.24);

      img {
        width: 100%;
        height: auto;
      }
    }

    &.message-personal {
      float: right;
      color: #fff;
      text-align: right;
      background: linear-gradient(120deg, #248A52, #257287);
      border-radius: 10px 10px 0 10px;

      &::before {
        left: auto;
        right: 0;
        border-right: none;
        border-left: 5px solid transparent;
        border-top: 4px solid #257287;
        bottom: -4px;
      }
    }

    &:last-child {
      margin-bottom: 30px;
    }

    &.new {
      transform: scale(0);
      transform-origin: 0 0;
      animation: bounce 500ms linear both;
    }

    &.loading {

      &::before {
        @include ball;
        border: none;
        animation-delay: .15s;
      }

      & span {
        display: block;
        font-size: 0;
        width: 20px;
        height: 10px;
        position: relative;

        &::before {
          @include ball;
          margin-left: -7px;
        }

        &::after {
          @include ball;
          margin-left: 7px;
          animation-delay: .3s;
        }
      }
    }

  }
}


.people {

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @media (min-width: $break-large) {
    margin: 0 -2em;
  }



  li {
    width: 100%;
    padding: 1em 0;

    @media (min-width: $break-large) {
      width: 50%;
      padding:  2em;
    }


    .test {
      position: relative;
      @media (min-width: $break-medium) {
        min-height: 10em;
      }

    }


    img {
      border-radius: 5em;
      display: block;
      margin: 0 auto 1em;
      width: 10em;

      @media (min-width: $break-medium) {
        position: absolute;
        top: 0;
        left: 0;
        margin: 0;
        max-width: 8em;
      }
    }


    p {
      margin-top: 2em;
      text-align: justify;

      @media (min-width: $break-medium) {
        margin-left: 10.5em;
      }

      strong {
        display: block;
        margin-top: 0.15em;
        text-indent: 0;
      }


    }

  }

}

#registerform {
  position: relative;
}
